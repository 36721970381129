import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import FontUrl2 from '../styles/fonts/Inter-italic.var-subset.woff2';
import FontUrl1 from '../styles/fonts/Inter-roman.var-subset.woff2';

function SEO({
  description,
  lang = 'en',
  meta,
  keywords,
  title,
  pathname,
  ogFileName = false,
  ogCustomPath = false,
  imageDescription = 'Polypane interface with "The browser for ambitious web developers" in front of it',
  scripts = [],
}) {

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description;
        const ogDescription = description || data.site.siteMetadata.ogDescription;

        const socialTitle = title ? `${title} | ${data.site.siteMetadata.ogPostTitle}` : `${data.site.siteMetadata.title}`;
        const pageTitleTemplate = title ? `%s | ${data.site.siteMetadata.ogPostTitle}` : `${data.site.siteMetadata.title}`;
        const ogImage = ogFileName
          ? `${data.site.siteMetadata.siteUrl + (ogCustomPath || '/og-images/') + ogFileName}.png`
          : data.site.siteMetadata.siteUrl + data.file.publicURL;

        const url = `${data.site.siteMetadata.siteUrl}${pathname || '/'}`;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title || data.site.siteMetadata.title}
            titleTemplate={pageTitleTemplate}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: socialTitle,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:description`,
                content: ogDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: ogImage,
              },
              {
                property: `og:image:alt`,
                content: imageDescription,
              },
              {
                property: `og:image:width`,
                content: '1200',
              },
              {
                property: `og:image:height`,
                content: '630',
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:site`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:text:title`,
                content: socialTitle,
              },
              {
                name: `twitter:title`,
                content: socialTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: ogImage,
              },
              {
                name: `twitter:image:alt`,
                content: imageDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
            script={scripts.map(({ src }) => ({ src, type: 'text/javascript' }))}
          >
            <link
              rel="alternate"
              type="application/rss+xml"
              title="Polypane Blog"
              href="https://polypane.app/rss.xml"
            />
            <link
              rel="chrome-webstore-item"
              href="https://chrome.google.com/webstore/detail/eofbapfmbfmpeplodnehlkkgpkklmapp"
            />
            <link rel="preload" as="font" href={FontUrl1} type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" as="font" href={FontUrl2} type="font/woff2" crossOrigin="anonymous" />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  pathname: null,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        ogPostTitle,
        ogTitle
        ogDescription
        author
      }
    }
    file: file(relativePath: { eq: "images/banner.jpg" }) {
      publicURL
    }
  }
`;
